<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>預約管理</b-breadcrumb-item>
            <b-breadcrumb-item active>預約列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="bookingPresets"
                :fields="fields"
              >
                <template #cell(name)="data">
                  <RouterLink
                    :to="{ name: 'BookingList', params: { id: data.item.id } }"
                  >
                    {{ data.item.name }}
                  </RouterLink>
                </template>
                <template #cell(is_enabled)="data">
                  <b-badge
                    v-if="data.item.config.is_enabled == true"
                    variant="success"
                  >
                    啟用
                  </b-badge>
                  <b-badge
                    v-if="data.item.config.is_enabled == false"
                    variant="secondary"
                  >
                    停用
                  </b-badge>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_CREATE])"
                    variant="inverse-success"
                    :to="{
                      name: 'BookingCreate',
                      params: { id: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-calendar-plus"></span> 新增預約
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_LIST])"
                    variant="inverse-warning"
                    :to="{ name: 'BookingList', params: { id: data.item.id } }"
                  >
                    <span class="mdi mdi-format-list-bulleted"></span>
                    查看預約資料
                  </b-button>
                  <copy-url-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_LIST])"
                    :url="generateLiffUrl(`booking/form/${data.item.id}`)"
                  />
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_PRESET_DELETE])"
                    variant="inverse-danger"
                    @click="deleteBookingPreset(data.item)"
                  >
                    <span class="mdi mdi-delete"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import bookingApi from "@/apis/booking";
import CopyUrlButton from "@/components/Dashboard/CopyUrlButton.vue";
import generateLiffUrl from "@/mixins/Dashboard/liffUrlHelper";
import { mapState } from "vuex";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { paginationMixin } from "@/mixins/pagination";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";

export default {
  components: {
    CopyUrlButton,
    CustomPagination,
  },
  mixins: [
    generateLiffUrl,
    paginationMixin,
  ],
  data() {
    return {
      consts: consts,
      showLoading: false,
      fieldsInit: false,
      fields: [
        {
          key: "name",
          label: "名稱",
          sortable: true,
        },
        {
          key: "bookings_count",
          label: "預約筆數",
          sortable: true,
        },
        {
          key: "is_enabled",
          label: "是否開放預約",
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      bookingPresets: [],
      totalRows: 0,
      query: {
        page: 1,
        per_page: 20,
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.fetchBookingPresets();
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  async mounted() {
    await this.fetchBookingPresets();
    this.initialized = true;
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async fetchBookingPresets() {
      try {
        this.showLoading = true;
        const params = { ...this.query };
        const response = await bookingApi.getBookingPresets(params);
        this.bookingPresets = response.data.data;
        this.totalRows = response.data.meta.total;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async deleteBookingPreset(bookingPreset) {
      try {
        this.showLoading = true;
        const result = await this.$swal({
          type: "warning",
          title: "您確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>刪除預約設定檔：${bookingPreset.name}</div>
              </div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        });

        if (!result.value) {
          return;
        }

        await bookingApi.deleteBookingPreset(bookingPreset.id);
        this.$swal("刪除成功", "", "success");
        await this.fetchBookingPresets();
      } catch (error) {
        console.error(error);
        this.$swal("刪除失敗", "", "error");
      } finally {
        this.showLoading = false;
      }
    },
  },
};
</script>
